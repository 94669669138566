import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "activeContent"];
  static values = { open: Boolean };

  toggle() {
    this.openValue = !this.openValue;
  }

  toggleWithPreventDefault(e) {
    e.preventDefault();
    this.toggle();
  }

  openValueChanged() {
    if (this.openValue) {
      this._show();
    } else {
      this._hide();
    }
  }

  _hide() {
    this.contentTargets.forEach((content) =>
      content.classList.add("visually-hidden")
    );

    this.activeContentTargets.forEach((content) =>
      content.classList.remove("visually-hidden")
    );
  }

  _show() {
    this.contentTargets.forEach((content) =>
      content.classList.remove("visually-hidden")
    );

    this.activeContentTargets.forEach((content) =>
      content.classList.add("visually-hidden")
    );
  }

  hide(event) {
    if (!this.element.contains(event.target) && this.openValue) {
      this.openValue = false;
    }
  }
}
