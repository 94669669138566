import flatpickr from "flatpickr";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["calendar", "picker", "date", "form"];
  static values = { minDate: String };

  connect() {
    this.render();
  }

  render() {
    flatpickr(this.pickerTarget, {
      inline: true,
      mode: "single",
      minDate: this.minDateValue || null,
      onChange: (_, dateStr) => {
        this.dateTarget.value = dateStr;
      },
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ["S", "M", "T", "W", "T", "F", "S"],
          longhand: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
        },
      },
    });
  }

  toggle() {
    this.calendarTarget.classList.toggle("visually-hidden");
  }

  close(event) {
    if (event && !this.element.contains(event.target)) {
      this.calendarTarget.classList.add("visually-hidden");
    }
  }
}
